import { container, title } from "assets/jss/material-kit-react.jsx";

const productPageStyle = {
  bwimages: {
    width: "100%",
    padding: "20px 0 20px 0",
    filter: "grayscale(1)",
    '&:hover': {
      filter: "none !important"
    }
  }
};

export default productPageStyle;
