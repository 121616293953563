import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

// React icons
// import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

import NavPills from "components/NavPills/NavPills.jsx";
import pillsStyle from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.jsx";

import servicesBanner from "assets/img/services-banner.png";
import servicesBannerMobile from "assets/img/services-banner-mobile.jpg";
import act1 from "assets/img/activity/Oil-Gas.jpg";
import act2 from "assets/img/activity/Marine-Equipment-Machine.jpg";
import act3 from "assets/img/activity/Petro-Chemical.jpg";
import act4 from "assets/img/activity/Oil-Greace.jpg";
import act5 from "assets/img/activity/Electrical-Trading.jpg";
import act6 from "assets/img/activity/tools-hardware.jpg";
import act7 from "assets/img/activity/Building-Materials.jpg";
import act8 from "assets/img/activity/Interior-Decor.jpg";
import act9 from "assets/img/activity/Furniture-Trading.jpg";
import act10 from "assets/img/activity/Office-Industrial-Supplies.jpg";
import del1 from "assets/img/delivered/1.jpg";
import del2 from "assets/img/delivered/2.jpg";
import del3 from "assets/img/delivered/3.jpg";
import del4 from "assets/img/delivered/4.jpg";
import del5 from "assets/img/delivered/5.jpg";
import del6 from "assets/img/delivered/6.jpg";
import del7 from "assets/img/delivered/7.jpg";
import del8 from "assets/img/delivered/8.jpg";
import del9 from "assets/img/delivered/9.jpg";

import { CssBaseline, Hidden, Typography } from "@material-ui/core";
import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
import FooterSection from "../LandingPage/Sections/FooterSection";
import productPageStyle from "../../assets/jss/material-kit-react/views/productPageStyles";

const dashboardRoutes = [];

class ServicesPage extends React.Component {
    render() {
        const { classes, ...rest } = this.props;
          
        return (
            <div>
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
                    // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
                    // brand="AL MIDIAFF"
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                    {...rest}
                />
                <CssBaseline />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section} style={{ padding: "0 15%" }}>
                        <div style={{ height: 160 }}></div>

                        <div className={classes.container}>
                            <GridContainer aria-label="breadcrumb">

                                <a href="/">Home /</a>
                &nbsp;
                <a href="#">Services</a>

                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={9} md={8} style={{}}>
                                    <h1 className={classes.title} style={{ color: "#6C648B" }}>Our Services</h1>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div style={{ background: "#fff", maxWidth: "100%" }}>
                            <div className={classes.section} style={{}}>
                                <Hidden xsDown>
                                <img
                                    src={servicesBanner}
                                    style={{ width: "100%" }}
                                />
                                </Hidden>
                                <Hidden smUp>
                                <img
                                    src={servicesBannerMobile}
                                    style={{ width: "100%" }}
                                />
                                </Hidden>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 40 }} id="activities"></div>
                    <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
                    <div className={classes.section} style={{ padding: "0 5%" }}>
                        <div className={classes.container} >
                            <GridContainer aria-label="breadcrumb">
                                <GridItem xs={12} sm={9} md={8} style={{}}>
                                    <h1 className={classes.title} style={{ color: "#6C648B" }}>Our Activities</h1>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div>
                            {/* <GridContainer>
                                <GridItem> */}
                            <NavPills
                                color="primary"
                                horizontal={{
                                    tabsGrid: { xs: 12, sm: 4, md: 3 },
                                    contentGrid: { xs: 12, sm: 8, md: 9 }
                                }}
                                tabs={[
                                    {
                                        tabButton: "UPSTREAM & DOWNSTREAM ONG EQUIPMENT",
                                        // tabIcon: Dashboard,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act1}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "MARINE EQUIPMENTS & MACHINERY ",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act2}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "PETRO CHEMICAL MATERIAL TRADING",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act3}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "OIL & GREASE TRADING",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act4}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "ELECTRICAL EQUIPMENT TRADING",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act5}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "TOOLS & EQUIPMENT TRADING",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act6}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "BUILDING MATERIAL TRADING",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act7}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "INTERIOR AND EXTERIOR DECOR WORKS",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act8}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "FURNITURE TRADING",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act9}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "OFFICE & INDUSTRIAL SUPPLIES",
                                        // tabIcon: Schedule,
                                        tabContent: (
                                            <span><br /><br /><br />
                                                <img
                                                    src={act10}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        )
                                    }
                                ]}
                            />
                            {/* </GridItem>
                            </GridContainer> */}
                        </div>
                    </div>
                    <div style={{ height: 60 }}></div>
                    <div style={{ background: "#E5E5E5", width: "100%" }}>
                        <GridContainer>
                            <Hidden smUp>
                                <GridItem xs={12} sm={9} md={9} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <h2 style={{ fontSize: "2.8em", textAlign: "center", background: "#fff", width: "100%" }}>WE HAVE SUCCESSFULLY DELIVERED</h2>
                                </GridItem>
                            </Hidden>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del1} style={{ width: "100%" }} />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del2} style={{ width: "100%" }} />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del3} style={{ width: "100%" }} />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del4} style={{ width: "100%" }} />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <Hidden xsDown>
                                <GridItem xs={12} sm={9} md={9} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <h2 style={{ fontSize: "2.8em", textAlign: "center", background: "#fff", width: "100%" }}>WE HAVE SUCCESSFULLY DELIVERED</h2>
                                </GridItem>
                            </Hidden>
                            <GridItem xs={12} sm={3} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img className={classes.imgGrayscale} src={del5} style={{ width: "100%", padding: "20px 0 20px 0" }} />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del6} style={{ width: "100%" }} />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del7} style={{ width: "100%" }} />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <img className={classes.imgGrayscale} src={del8} style={{ width: "100%" }} />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                {/* <style>
                                    #imgbw {{
                                        width: "100%",
                                        padding: "20px 0 20px 0",
                                        filter: "grayscale(1)"
                                    }}
                                    #imgbw {{
                                        filter: "none !important"
                                    }}
                                </style> */}
                                <div className={classes.bwimages}>
                                    <img className={classes.imgGrayscale} src={del9} style={{width:"100%"}} />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
                    <div style={{ background: "#6C648B", width: "100%" }}>
                        <div className={classes.container} >
                            <NewsLetterSection />
                        </div>
                    </div>

                    <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
                    <FooterSection />


                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(landingPageStyle, productStyle, pillsStyle, productPageStyle)(ServicesPage);
